import Vue from "vue";

/*TITLE*/
document.title = "RESIDENCIAL HORT | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "RESIDENCIAL HORT";
Vue.prototype.$subtitle = "C/ L´Hort 35-37 Albal";

/*INTRO*/
Vue.prototype.$promoter = "RESIDENCIAL HORT";
Vue.prototype.$introSubtitle = "C/ L´Hort 35-37 Albal";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091433.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091433.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091433.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091433.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091433.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091433.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091433.jpeg",
  },
  {
    src: "1-bathroom1--20221124091433.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091433.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20l´hort%2035%2037%20albal",
    text: "Calle L´Hort 35-37 Albal",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carrer%20del%20mestre%20clavé%203%2046001%20valència",
    text: "Carrer del Mestre Clavé, 3, 46001 València",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:606.704.460–607.190.958",
    text: "606.704.460–607.190.958",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@binomio-arquitectura.com",
    text: "info@binomio-arquitectura.com",
  },
];
